import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Column } from "../Grid"
import Image from "../Image"
import "./styles.scss"

const HeroHome = ({ title, subheading, image }) => {
  return (
    <Container>
      <Row>
        <Column span={6}>
          <div className="hero__left">
            <h1 className="home__hero-title">{title}</h1>
            <h2>{subheading}</h2>
          </div>
        </Column>
        <Column span={6}>
          <div className="hero__right">
            <Image filename={image} className="hero__image" />
          </div>
        </Column>
      </Row>
    </Container>
  )
}

HeroHome.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default HeroHome
